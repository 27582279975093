import React from 'react';
import Lottie from 'react-lottie';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, graphql } from 'gatsby';
import { TimelineLite } from 'gsap';
import Observer from 'react-intersection-observer';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import LightBluekey from '../../static/assets/light_blue_key.svg';
import LightBluekeySecond from '../../static/assets/light_blue_key_second.svg';
import LightBluekeyReplica from '../../static/assets/light_blue_key_replica.svg';
import positioningBottom from '../../static/img/positioning/positioningImg.png';
import YellowkeySecond from '../../static/assets/yellow_key_second.svg';
import WhiteKey from '../../static/assets/white_key.svg';
import WhiteKeyTwo from '../../static/assets/white_key_two.svg';
import BlueLogo from '../../static/assets/positioning/BP_blue_New.svg';
import ReadMoreWhite from '../../static/assets/Readmore-white.svg';
import ReadMore from '../../static/assets/Readmore.svg';

import * as globalAnimation from '../animations/globalAnimation';
import {
  animateTranslate,
  animateDescription,
  animateBottomTranslate,
  animateCommercialStrategies,
  animateHealthcare,
} from '../animations/positioning';
import animationData from '../animations/venn_diagram.json';

export default class Positioning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoPlay: false,
    };
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.animateBG(this.tl, '.orange_bg', -200);
    animateTranslate(
      this.tl,
      '.svg-line__line_lightblue',
      '.svg_circle_lightblue',
      '.bp-logo',
      '#description'
    );
  }

  animateSectionTwo = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateDescription(
        tl,
        '.svg-line__line_lightblue_second',
        '.svg_circle_lightblue_second',
        '.positioning-image2',
        '.lightblue_bg',
        '#positioning-description2',
        '-70px',
        '-70px'
      );
    }
  };

  animateCommercialHeading = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      globalAnimation.animateHeading(tl, '.second-title', '.second-subtitle');
    }
  };

  animateBottomSection = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateBottomTranslate(
        tl,
        '.svg-line__line_white',
        '.svg_circle_white',
        '.bottom-image',
        '#bottom-description-one',
        '.svg-line__line_white_two',
        '.svg_circle_white_two',
        '#bottom-description-two',
        '#cta'
      );
    }
  };

  animateVenn = inView => {
    if (inView) {
      this.setState({ autoPlay: true });
    }
  };

  animateHealthcareApproach = inView => {
    const tlApp = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateHealthcare(tlApp, '.fade-text-up');
    }
  };

  animateLeftDesc = inView => {
    const tlLeftDesc = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateCommercialStrategies(
        tlLeftDesc,
        '.svg-line__line_yellow_second',
        '.svg_circle_yellow_second',
        '#left-text',
        '#left-description'
      );
    }
  };

  animateRightDesc = inView => {
    const tlRightDesc = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateCommercialStrategies(
        tlRightDesc,
        '.svg-line__line_lightblue_replica',
        '.svg_circle_lightblue_replica'
      );
    }
  };

  render() {
    const { autoPlay } = this.state;
    const defaultOptions = {
      loop: false,
      autoplay: autoPlay,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    const {
      data: {
        markdownRemark: {
          frontmatter: {
            title,
            sectionOne,
            sectionTwo: { image, description },
            commercialStrategists,
            commercialStrategists: { boxText, leftDescription, rightDescription },
            bottomSection,
          },
        },
      },
    } = this.props;
    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Positioning" />
        <Container fluid className="main_wrapper section-one">
          <Row>
            <Col>
              <div className="wrapper">
                <div className="page_heading_section text-center">
                  <h3 className="page_heading text-uppercase d-lg-block d-none title">{title}</h3>
                  <h4 className="text-uppercase page_heading_child subtitle">{title}</h4>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="py-3 py-lg-5">
            <Col xs={12} sm={12} lg={{ span: 5 }}>
              <div className="bg-orange orange_bg">
                <div className="temp_translate logo_translate">
                  <BlueLogo className="bp-logo" width="350" />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} lg={{ span: 6 }}>
              <div className="description-wrapper positioning px-lg-5 py-3 py-lg-0">
                <div className="position-relative line line-horizontal">
                  <LightBluekey />
                </div>
                <p id="description" className="story_description text-muted">
                  {sectionOne.descriptionOne}
                </p>
                <p id="description" className="story_description text-muted">
                  {sectionOne.descriptionTwo}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="section-two">
          <Observer onChange={this.animateSectionTwo} triggerOnce>
            <Row className="position-relative pt-lg-5">
              <Col xs={{ order: 2, span: 12 }} lg={{ span: 4, order: 1, offset: 1 }}>
                <div className="wrapper pl-0 py-lg-0 pr-lg-0">
                  <div className="position-relative line line-horizontal">
                    <LightBluekeySecond />
                  </div>
                  <p id="positioning-description2">{description}</p>
                </div>
              </Col>
              <Col xs={{ order: 1, span: 12 }} className="d-lg-none">
                <div>
                  <Img className="img-fluid" fluid={image.childImageSharp.fluid} />
                </div>
              </Col>
              <Col
                xs={{ order: 1, span: 12 }}
                lg={{ span: 5, order: 2, offset: 2 }}
                className="d-none d-lg-block"
              >
                <div className="bg-appcolor-light lightblue_bg">
                  <div className="">
                    <Img
                      className="img-fluid positioning-image2"
                      fluid={image.childImageSharp.fluid}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Observer>
        </Container>
        <Container fluid className="section-three">
          <Observer onChange={this.animateCommercialHeading} triggerOnce>
            <Row className="bg-appcolor">
              <Col>
                <div className="wrapper">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none white second-title">
                      {commercialStrategists.title}
                    </h3>
                    <h4 className="text-uppercase page_heading_child text-white second-subtitle ">
                      {commercialStrategists.title}
                    </h4>
                  </div>
                </div>
              </Col>
            </Row>
          </Observer>
          <Row className="bg-appcolor text-white">
            <Col xs={{ order: 1, span: 12 }} lg={{ order: 2, span: 4 }} md={{ span: 6 }}>
              <Observer onChange={this.animateLeftDesc} triggerOnce>
                <div className="animate-fade-up">
                  <h5 id="left-text" className="text-left text-lg-right">
                    {leftDescription.title}
                  </h5>
                  <div className="text-left text-lg-right line line-horizontal ">
                    <YellowkeySecond className="rotate180 mb-2" />
                  </div>
                  <p id="left-description" className="text-left text-lg-right">
                    {leftDescription.description}
                  </p>
                </div>
              </Observer>
            </Col>
            <Col xs={{ order: 3, span: 12 }} lg={{ order: 2, span: 4 }} md={{ span: 12 }}>
              <div className="text-center px-md-4 pt-md-4 p-lg-0">
                <Observer onChange={this.animateVenn} triggerOnce>
                  <div>
                    <Lottie
                      options={defaultOptions}
                      isClickToPauseDisabled
                      isStopped={this.state.isStop}
                    />
                    {/* <img
                    src={commercialStrategists.image.publicURL}
                    alt=""
                    height="100%"
                    width="100%"
                  /> */}
                  </div>
                </Observer>
              </div>
            </Col>
            <Col xs={{ order: 2, span: 12 }} lg={{ order: 3, span: 4 }} md={{ span: 6 }}>
              <Observer onChange={this.animateRightDesc} triggerOnce>
                <div className="animate-fade-up">
                  <h5 id="left-text">{rightDescription.title}</h5>
                  <div className="line line-horizontal">
                    <LightBluekeyReplica className="mb-2" />
                  </div>
                  <p id="left-description">{rightDescription.description}</p>
                </div>
              </Observer>
            </Col>
          </Row>
          <Row className="bg-appcolor pb-3 pb-lg-5">
            <Col>
              <Observer onChange={this.animateHealthcareApproach} triggerOnce>
                <div className="justify-content-center d-flex fade-text-up">
                  <div className="overlapping-wrapper wrapper">
                    <div className="text-center text-semibold">
                      {boxText}
                      <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center mt-3">
                        <div className="d-flex justify-content-center pl-lg-4">
                          <div className="learn_more py-3">
                            <Link
                              to="/healthcare-consulting/"
                              className="bordered-btn text-center py-3 py-lg-0"
                            >
                              Learn about our approach
                              <ReadMore
                                className="arrow-translate mb-0"
                                height="10px"
                                width="40px"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Observer>
            </Col>
          </Row>
        </Container>
        <Container fluid className="section-four position-bottom-section">
          <Observer onChange={this.animateBottomSection} triggerOnce>
            <Row className="text-white bg-appcolor-light">
              <Col xs={{ order: 1, span: 12 }} className="d-lg-none py-3 py-lg-0">
                <img src={positioningBottom} className="img-fluid" alt="" />
              </Col>
              <Col xs={{ order: 1, span: 12 }} lg={{ order: 1, span: 4 }}>
                <div className="content-wrapper py-lg-5">
                  <div>
                    <div className="position-relative line line-horizontal">
                      <WhiteKey />
                    </div>
                    <p id="bottom-description-one" className="story_description">
                      {bottomSection.descriptionOne}
                    </p>
                  </div>

                  <div>
                    <div className="position-relative line line-horizontal">
                      <WhiteKeyTwo />
                    </div>
                    <p id="bottom-description-two" className="story_description">
                      {bottomSection.descriptionTwo}
                    </p>
                  </div>
                  <div className="white-learnmore d-flex justify-content-center justify-content-lg-start">
                    <div id="cta" className="py-3">
                      <Link
                        to="/typical-engagements/"
                        className="bordered-btn text-semibold text-center"
                      >
                        View typical engagements
                        <ReadMoreWhite
                          className="arrow-translate mb-0"
                          height="10px"
                          width="40px"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                lg={{ span: 8, order: 2 }}
                className="w-100 d-none d-lg-block bg-image-positioning bottom-image"
              />
            </Row>
          </Observer>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "positioning" } }) {
      frontmatter {
        title
        sectionOne {
          image {
            publicURL
          }
          descriptionOne
          descriptionTwo
        }
        sectionTwo {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          description
        }
        commercialStrategists {
          image {
            publicURL
          }
          title
          leftDescription {
            title
            description
          }
          rightDescription {
            title
            description
          }
          boxText
        }
        bottomSection {
          descriptionOne
          descriptionTwo
        }
      }
    }
  }
`;
