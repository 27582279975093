import { Power1, Power2 } from 'gsap';

export function animateTranslate(tl, svgLine, svgCircle, image, description) {
  const o = 1.3;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo('.description_column', 0.3, { opacity: 0 }, { opacity: 1 }, 0.3)
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      description,
      2.3,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      2.3
    )
    .staggerFrom(
      image,
      o,
      {
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    );
}

export function animateDescription(tl, svgLine, svgCircle, image, container, description, X, Y) {
  const o = 1.3;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo('.description_column', 0.3, { opacity: 0 }, { opacity: 1 }, 0.3)
    .staggerFromTo(svgLine, 0.7, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.7,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      description,
      1.7,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      1.7
    )
    .staggerFrom(
      container,
      1.3,
      {
        x: 120,
        y: 30,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerTo(
      image,
      1.3,
      {
        x: X,
        y: Y,
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      o
    );
}

export function animateCommercialStrategies(tl, svgLine, svgCircle, title, description) {
  // const o = 1;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo(svgLine, 1.9, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      1.9,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      2.7,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      2.7
    )
    .staggerFrom(
      description,
      2.9,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      2.9
    );
}

export function animateHealthcare(tl, el) {
  return tl.staggerFrom(
    el,
    2.5,

    {
      opacity: 0,
      y: 50,
      ease: Power1.easeOut,
    },
    0,
    2.5
  );
}

export function animateBottomTranslate(
  tl,
  svgLineOne,
  svgCircleOne,
  image,
  descriptionOne,
  svgLineTwo,
  svgCircleTwo,
  descriptionTwo,
  cta
) {
  const o = 1;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo('.description_column', 0.3, { opacity: 0 }, { opacity: 1 }, 0.3)
    .staggerFromTo(
      svgLineOne,
      0.5,
      { attr: { width: 0 } },
      { attr: { width: 88 }, clearProps: 'all' }
    )
    .staggerFromTo(
      svgCircleOne,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      descriptionOne,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      image,
      o,
      {
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFromTo(
      svgLineTwo,
      0.9,
      { attr: { width: 0 } },
      { attr: { width: 88 }, clearProps: 'all' }
    )
    .staggerFromTo(
      svgCircleTwo,
      0.9,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFrom(
      descriptionTwo,
      2.3,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      2.3
    )
    .staggerFrom(
      cta,
      2.5,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      2.5
    );
}
